import { useMutation, useQueryClient } from '@tanstack/vue-query';
import * as AuthApi from '@/api/auth';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';
import useDeliveryStore from '@/stores/deliveryStore';

type SkipOptions = {
  config?: MutationConfig<typeof AuthApi.logout>;
};

const useLogout = ({ config }: SkipOptions = {}) => {
  const queryClient = useQueryClient();
  const deliveryStore = useDeliveryStore();
  return useMutation({
    ...config,
    mutationFn: AuthApi.logout,
    onSuccess: () => {
      queryClient.setQueriesData(queries.customer.details, null);
      //TO DO reset upcoming and past orders
      queryClient.invalidateQueries({
        queryKey: [
          queries.orders.scheduleWeeks,
          queries.orders.scheduleWeeks,
          queries.addresses.all,
          queries.payments.all,
          queries.subscriptions.all,
          queries.baskets.all,
        ],
      });
      deliveryStore.resetStore();
    },
  });
};

export default useLogout;
