import { Router } from 'vue-router';
import useCurrentCustomerStore from '@/stores/currentCustomerStore';
import {
  getProductCategoriesFromTags,
  getProductCategoryFromTags,
  getSectionTitleFromTags,
  sha256,
} from '.';
import { BasketItem, ProductOrder, notEmpty } from '@pasta-evangelists/pasta-types';
import useBasketStore from '@/stores/basketStore';
import useWeeklyMenuStore from '@/stores/weeklyMenusStore';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import useClientStatusStore from '@/stores/useClientStatusStore';
import { getSizedImage } from './image';
import { TakeawayBasketItemWithModifiers } from '@/types/pastanow';
import { MenuType } from './types';
import usePastaNowMenu from '@/stores/pastaNowMenuStore';

interface DataLayerCheckoutProduct {
  name: string;
  id: string;
  price?: number;
  brand?: 'Regular' | 'Limited Range' | 'Highly Recommended';
  category?: 'Meat & Fish' | 'Free From' | 'Vegetarian' | 'Side & Desserts';
  variant?: 'Single Portion' | 'Double Portion';
  quantity?: number;
  dimension2?: string; // the delivery schedule (ex: Every 2 weeks)
  dimension3?: string; // the delivery day (ex: Friday, 13th August)
  dimension4?: string; // the subsequent delivery day (ex: Friday)
  sku?: string;
}

export interface DataLayerCheckoutProductGA4 {
  item_name: string;
  item_id: string;
  price?: number;
  discount?: number;
  item_brand?: 'Shopify' | 'Deliverect';
  item_category?: string[]; // Filter tag as a category
  item_category2?: string[]; // Section (ex. Customer Favourites, This week's special)
  item_variant?: 'Single' | 'Grande';
  item_modifiers?: number;
  item_modifiers_list?: string[];
  quantity?: number;
  sku?: string;
  menu_type?: MenuType;
  image?: {
    src: string;
    alt: string;
    thumb_src: string;
  };
}

export interface DataLayerCart {
  value: number;
  add_to_cart_source?: string;
  add_to_cart_menu_week?: number;
}

export interface PaymentEventData {
  payment_method: string;
  payment_outcome: string;
  menu_type: MenuType;
}

export interface CouponEventData {
  coupon_added: string;
  coupon_added_from: string;
  menu_type: MenuType;
}

export const trackBeginCheckoutEvent = (
  products: DataLayerCheckoutProductGA4[],
  actionField: PurchaseActionField
) => {
  window.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer?.push({
    event: 'begin_checkout',
    ecommerce: {
      currencyCode: 'GBP',
      ...actionField,
      affiliation: 'Website',
      tax: 0,
      items: [...products],
    },
  });
};

export const sendCheckoutEvent = (
  step: number,
  products: DataLayerCheckoutProduct[],
  basketId?: string
) => {
  window.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer?.push({
    event: 'checkout',
    basketId,
    ecommerce: {
      currencyCode: 'GBP',
      checkout: {
        actionField: { step },
        products,
      },
    },
  });
};

interface GlobalVariableEventParam {
  login_status: 'Logged In' | 'Logged Out';
  user_type: 'Customer' | 'Guest';
  customer_id?: string;
  email_address?: string; //  Shoulbe hashed with SHA256
  has_purchased?: 'Yes' | 'No';
  shopify_id?: string;
  page_category: string;
  page_sub_category?: string;
  ln?: string;
  fn?: string;
  em?: string;
}

const hashVariables = async (variables: GlobalVariableEventParam) => {
  const objectHashed = {} as Record<string, string>;
  const { ln, fn, em } = variables;

  if (ln) {
    objectHashed['lnHashed'] = await sha256(ln.toLowerCase());
  }

  if (fn) {
    objectHashed['fnHashed'] = await sha256(fn.toLowerCase());
  }
  if (em) {
    objectHashed['emHashed'] = await sha256(em.trim().toLowerCase());
  }

  return objectHashed;
};

export const globalVariableEvent = async (params: GlobalVariableEventParam) => {
  const extraHashedVariables = await hashVariables(params);

  window.dataLayer?.push({
    ...params,
    ...extraHashedVariables,
    site_language: 'en-gb',
    site_country: 'UK',
    site_environment: import.meta.env.VITE_IS_PROD ? 'Production' : 'Staging',
  });
};

export interface PurchaseActionField {
  transaction_id: string;
  coupon?: string;
  value: number;
  tax?: number;
  payment_method?: string;
  menu_type: string;
  affiliation?: string;
  timeframe?: string;
  shipping: number;
}

export interface UserProvidedData {
  phone: string;
  street: string;
  city: string;
  region: string;
  country: string;
  postalCode: string;
}

export const purchaseEvent = async (
  products: DataLayerCheckoutProductGA4[],
  actionField: PurchaseActionField,
  { phone, city, country, postalCode }: UserProvidedData
) => {
  window.dataLayer?.push({ ecommerce: null });
  window.dataLayer?.push({
    event: 'hashed_purchase_data',
    hashedPhone: await sha256(phone.trim().toLowerCase()),
    hashedCity: await sha256(city.toLowerCase()),
    hashedCountry: await sha256(country.toLowerCase()),
    hashedPostalCode: await sha256(postalCode.toLowerCase()),
  });
  window.dataLayer?.push({
    event: 'purchase',
    ecommerce: {
      currencyCode: 'GBP',
      ...actionField,
      affiliation: 'Website',
      tax: 0,
      items: products,
    },
  });
};

export const addToCartEvent = (
  cartData: DataLayerCart,
  products: DataLayerCheckoutProductGA4[]
) => {
  window.dataLayer?.push({ ecommerce: null });
  window.dataLayer?.push({
    event: 'add_to_cart',
    ecommerce: {
      ...cartData,
      currencyCode: 'GBP',
      items: [...products],
    },
  });
};
export const removeFromCartEvent = (products: DataLayerCheckoutProduct) => {
  window.dataLayer?.push({ ecommerce: null });
  window.dataLayer?.push({
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: 'GBP',
      remove: {
        products: products,
      },
    },
  });
};

export interface ViewItemData {
  value: number;
  item_list_id: string;
  item_list_name: string;
  items: DataLayerCheckoutProductGA4[];
}

export const viewItemEvent = (data: ViewItemData) => {
  window.dataLayer?.push({ ecommerce: null });
  window.dataLayer?.push({
    event: 'view_item',
    ecommerce: {
      currencyCode: 'GBP',
      ...data,
    },
  });
};

interface PageViewEventParams {
  pageUrl: string;
  pagePath: string;
  pageTitle: string;
}

export const pageViewEvent = ({ pageUrl, pagePath, pageTitle }: PageViewEventParams) => {
  window.dataLayer?.push({
    pageUrl,
    pagePath,
    pageTitle,
    event: 'trackPageview',
  });
};

export const trackNavigationEvent = (vueRouter: Router, ignoredViews: string[] = []) => {
  vueRouter.afterEach(async to => {
    if (
      typeof to.name !== 'string' ||
      (Array.isArray(ignoredViews) && ignoredViews.includes(to.name))
    ) {
      return;
    }

    const baseUrl: string = vueRouter.options?.history?.base ?? '';
    let fullUrl: string = baseUrl;
    if (!fullUrl.endsWith('/')) {
      fullUrl += '/';
    }
    fullUrl += to.fullPath.startsWith('/') ? to.fullPath.substr(1) : to.fullPath;

    const userStore = useCurrentCustomerStore();
    const clientStatusStore = useClientStatusStore();

    const params: GlobalVariableEventParam = {
      login_status: userStore.email ? 'Logged In' : 'Logged Out',
      user_type: userStore.email ? 'Customer' : 'Guest',
      page_category: to.name,
    };

    if (userStore.email) {
      params.email_address = await sha256(userStore.email);
      params.has_purchased = clientStatusStore.hasOrders ? 'Yes' : 'No';
      params.customer_id = userStore.id;
      params.shopify_id = userStore.extId || '';
      params.ln = userStore.lastName;
      params.fn = userStore.firstName;
      params.em = userStore.email;
    }

    globalVariableEvent(params);

    const isOpeningModal = document.location.href.endsWith('#modal');

    if (isOpeningModal) return;

    pageViewEvent({
      pageUrl: document.location.href,
      pagePath: document.location.pathname,
      pageTitle: document.title,
    });
  });
};

type BasketItemWithTags = BasketItem & { tags: string[] };

export const convertBasketProductsIntoDataLayerProductsOld = (
  basketItems: BasketItemWithTags[]
): DataLayerCheckoutProduct[] => {
  const weeklyMenuStore = useWeeklyMenuStore();
  return basketItems.map(basketItem => {
    const category = getProductCategoryFromTags(
      basketItem.tags
    ) as DataLayerCheckoutProduct['category'];

    return {
      name: basketItem.attributes.title.replaceAll('"', ''),
      id: basketItem.attributes.productVariantExtId.toString(),
      price: parseFloat(basketItem.attributes.price),
      brand: 'Regular',
      category: category ?? undefined,
      variant: basketItem.attributes.isDouble ? 'Double Portion' : 'Single Portion',
      quantity: basketItem.attributes.quantity,
      sku:
        weeklyMenuStore.productVariants?.[basketItem.attributes.productVariantExtId]?.sku ??
        undefined,
      image: {
        src: basketItem.attributes.imageUrl,
        alt: basketItem.attributes.title,
        thumb_src: getSizedImage(basketItem.attributes.imageUrl, 'thumb'),
      },
    };
  });
};

export const convertBasketProductsIntoDataLayerProducts = (
  basketItems: BasketItemWithTags[]
): DataLayerCheckoutProductGA4[] => {
  const weeklyMenuStore = useWeeklyMenuStore();
  return basketItems.map(basketItem => {
    const category = getProductCategoriesFromTags(
      basketItem.tags
    ) as DataLayerCheckoutProductGA4['item_category'];
    const category2 = getSectionTitleFromTags(basketItem.tags);
    const discountPerItem =
      parseFloat(basketItem.attributes.discount || '0') / basketItem.attributes.quantity;
    return {
      item_name: basketItem.attributes.title.replaceAll('"', ''),
      item_id: basketItem.attributes.productVariantExtId.toString(),
      price: parseFloat(basketItem.attributes.price),
      discount: parseFloat(discountPerItem.toFixed(2)),
      item_brand: 'Shopify',
      item_category: category ?? undefined,
      item_category2: category2,
      item_variant: basketItem.attributes.isDouble ? 'Grande' : 'Single',
      quantity: basketItem.attributes.quantity,
      sku:
        weeklyMenuStore.productVariants?.[basketItem.attributes.productVariantExtId]?.sku ??
        undefined,
      image: {
        src: basketItem.attributes.imageUrl,
        alt: basketItem.attributes.title,
        thumb_src: getSizedImage(basketItem.attributes.imageUrl, 'thumb'),
      },
    };
  });
};

export const convertOrderProductsIntoDataLayerProducts = (
  items: ProductOrder[]
): DataLayerCheckoutProductGA4[] => {
  const weeklyMenuStore = useWeeklyMenuStore();
  return items.map(item => {
    const category = getProductCategoriesFromTags(
      item.tags
    ) as DataLayerCheckoutProductGA4['item_category'];
    const category2 = getSectionTitleFromTags(item.tags);
    const discountPerItem = parseFloat(item.discount || '0') / item.quantity;
    return {
      item_name: item.title.replaceAll('"', ''),
      item_id: item.productVariantExtId.toString(),
      price: parseFloat(item.price),
      discount: parseFloat(discountPerItem.toFixed(2)),
      item_brand: 'Shopify',
      item_category: category ?? undefined,
      item_category2: category2,
      item_variant: item.variantTitle?.includes('Double') ? 'Grande' : 'Single',
      quantity: item.quantity,
      sku: weeklyMenuStore.productVariants?.[item.productVariantExtId]?.sku ?? undefined,
      image: {
        src: item.imageUrl,
        alt: item.title,
        thumb_src: getSizedImage(item.imageUrl, 'thumb'),
      },
    };
  });
};

export const convertPastaNowBasketIntoDataLayerProducts = (
  basketItems?: TakeawayBasketItemWithModifiers[]
): DataLayerCheckoutProductGA4[] => {
  if (!basketItems) return [] as DataLayerCheckoutProductGA4[];
  const pastaNowMenu = usePastaNowMenu();
  return basketItems.map(item => {
    const itemPrice = parseFloat(item.total);
    const categories = pastaNowMenu
      .getCategoriesForProduct(item.productId)
      .map(category => category.attributes.name);
    const modifiers = item.appliedModifiers.map(modifier => modifier.title);
    return {
      item_id: item.productId.toString(),
      item_name: item.title,
      price: itemPrice,
      image: {
        src: item.imageUrl,
        alt: `${item.title} image`,
        thumb_src: item.imageUrl,
      },
      discount: parseFloat(item.discount),
      quantity: item.quantity,
      item_brand: 'Deliverect',
      item_modifiers: modifiers.length,
      item_modifiers_list: modifiers,
      item_category: categories,
      item_variant: modifiers.find(
        element =>
          element.toLowerCase().includes('grande') ||
          element.toLowerCase().includes('bigger') ||
          element.toLowerCase().includes('double')
      )
        ? 'Grande'
        : 'Single',
    };
  });
};

export const trackCheckoutStep = (step: number) => {
  const basketStore = useBasketStore();
  const weeklyMenuStore = useWeeklyMenuStore();

  const basketItems = Object.values(basketStore.normalizedBasket?.basketItem ?? {}).map(
    basketItem => {
      const tags =
        weeklyMenuStore.productVariants?.[basketItem.attributes.productVariantExtId]?.tags ?? [];
      return {
        ...basketItem,
        tags,
      };
    }
  );
  const products = convertBasketProductsIntoDataLayerProductsOld(basketItems);
  const productsWithDimensions = products
    .map(getProductWithCustomDimensionsForStep(step))
    .filter(notEmpty);
  sendCheckoutEvent(step, productsWithDimensions, basketStore.basketKey || '');
};

export const trackCheckoutPages = (vueRouter: Router) => {
  // step 1 is the frequency modal which is not a route
  const trackRoutes = [
    { route: 'delivery-address', step: 3 },
    { route: 'delivery-instructions', step: 4 },
    { route: 'checkout-payment', step: 5 },
    { route: 'checkout-signup', step: 2 },
    { route: 'checkout-login', step: 2 },
  ];

  vueRouter.afterEach(to => {
    const indexOfTrackRoutes = trackRoutes.findIndex(track => track.route === to.name);
    if (indexOfTrackRoutes > -1) {
      const step = trackRoutes[indexOfTrackRoutes].step;
      trackCheckoutStep(step);
    }
  });
};

export const getBasketItems = (): AnalyticBasketItem[] => {
  const basketStore = useBasketStore();
  const weeklyMenuStore = useWeeklyMenuStore();

  return Object.values(basketStore.normalizedBasket?.basketItem ?? {}).map(basketItem => {
    const tags =
      weeklyMenuStore.productVariants?.[basketItem.attributes.productVariantExtId]?.tags ?? [];
    const { id, attributes } = basketItem;
    const price = Math.round((parseFloat(attributes.price) * 100 + Number.EPSILON) * 100) / 100;
    return {
      id,
      ...attributes,
      isDouble: !!attributes.isDouble,
      mealWeek: attributes.mealWeek ?? '',
      price,
      tags,
      sku: weeklyMenuStore.productVariants?.[basketItem.attributes.productVariantExtId]?.sku,
    };
  });
};

export type AnalyticBasketItem = {
  id: string;
  price: number;
  tags: string[];
  sku?: string | null;
  discount: string;
  imageUrl: string;
  isAddon?: boolean;
  isDouble: boolean;
  mealWeek: string;
  quantity: number;
  title: string;
  productVariantId: number;
  productVariantExtId: string;
  productId: number;
};

export const convertMenuToWeekIndex = (menu: string) => {
  const weeklyMenuStore = useWeeklyMenuStore();

  const foundIndex = weeklyMenuStore.availableWeeks.findIndex(week => week === menu);

  if (foundIndex >= 0) {
    return foundIndex + 1;
  }

  return -1;
};

export const getProductWithCustomDimensionsForStep = (step?: number) => (
  product: DataLayerCheckoutProduct
) => {
  const basketStore = useBasketStore();
  dayjs.extend(weekOfYear);

  if (!basketStore.normalizedBasket || !basketStore.normalizedBasket.basketItem) return null;
  const basketItem = Object.values(basketStore.normalizedBasket.basketItem).find(
    basketI => basketI.attributes.productVariantExtId.toString() === product.id
  );

  if (!basketItem) return null;

  const diffInWeeks =
    dayjs(basketItem.attributes.mealWeek).week() - dayjs(basketStore.deliveryDate).week();

  const deliveryDay = dayjs(basketStore.deliveryDate).add(diffInWeeks, 'week');

  const deliveryScheduled = `Every ${basketStore.frequency} week${
    (basketStore.frequency || 1) > 1 ? 's' : ''
  }`;
  const subsequentDeliveryDay = dayjs(deliveryDay).format('dddd');

  switch (step) {
    case 1:
      return product;
    case 2:
    case 3:
      return {
        ...product,
        dimension2: deliveryScheduled,
      };
    case 4:
      return {
        ...product,
        dimension3: deliveryDay.format('dddd Do MMM'),
      };
    case 5:
      return {
        ...product,
        dimension4: subsequentDeliveryDay,
      };
    default:
      return {
        ...product,
        dimension2: deliveryScheduled,
        dimension3: deliveryDay.format('dddd Do MMM'),
        dimension4: subsequentDeliveryDay,
      };
  }
};

export interface SignupEventData {
  marketing: boolean;
  method: string;
  success: 'Success' | 'Fail';
}

export const trackSignupEvent = (data: SignupEventData) => {
  window.dataLayer?.push({
    event: 'submitSignupForm',
    marketing: Number(data.marketing),
  });
  window.dataLayer?.push({
    event: 'signup',
    method: data.method,
    login_signup_success_fail: data.success,
  });
};

export const trackCustomEvent = (eventName: string, eventData?: Object) => {
  window.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer?.push({
    event: eventName,
    ...eventData,
  });
};

export const trackClickEvent = (id: string, text: string) => {
  trackCustomEvent('CTA_clicked', {
    click_id: id,
    click_text: text,
    click_url: window.location.href,
  });
};
export interface ViewCartParams {
  currency: string;
  value: number;
  menu_type: MenuType;
}
