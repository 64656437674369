import { acceptHMRUpdate, defineStore } from 'pinia';
import {
  TakeawayMenuCategory,
  TakeawayMenuProduct,
} from '@pasta-evangelists/pasta-types/dist/restaurants';
import usePastaNowBasketStore from './pastaNowBasketStore';
import useGetMenu from '@/api/queries/pensa/useGetMenu';
import useGetSnoozedProducts from '@/api/queries/pensa/useGetSnoozedProducts';
import useMenuHelper from '@/composables/useMenuHelper';

const usePastaNowMenu = defineStore('pastaNowMenu', () => {
  const pastaNowBasket = usePastaNowBasketStore();
  const restaurantId = computed(() => pastaNowBasket.restaurantId);

  const fulfillmentType = computed(() => pastaNowBasket.fulfillmentType);
  const productsQuery = useGetMenu(restaurantId, fulfillmentType);
  const snoozedProductsQuery = useGetSnoozedProducts(restaurantId, fulfillmentType);
  const upsellCategoriesNames = ['Starters & Sides', 'Desserts', 'Alcoholic Drinks'];

  const {
    snoozedProductIds,
    getSubProductsForProduct,
    getTakeawayProductForProductId,
  } = useMenuHelper({
    menu: productsQuery.data,
    snoozedProducts: snoozedProductsQuery.data,
  });

  const isLoading = computed(() => productsQuery.isLoading.value);
  const menuError = computed(() => productsQuery.error.value);

  const categories = computed(() => {
    if (
      !productsQuery.data.value?.takeawayMenuCategory ||
      !Object.keys(productsQuery.data.value?.takeawayMenuCategory).length
    ) {
      return null;
    }
    return Object.values(productsQuery.data.value.takeawayMenuCategory)
      .sort((a, b) => a.attributes.position - b.attributes.position)
      .reduce((acc, curr) => {
        if (!acc[curr.id]) acc[curr.id] = curr;
        return acc;
      }, {} as Record<string, TakeawayMenuCategory>);
  });

  const productInCategories = computed(() => {
    if (!categories.value) return null;
    return Object.values(categories.value).reduce((acc, curr) => {
      if (!acc[curr.id]) {
        acc[curr.id] = [];
      }
      const variants = [...curr.attributes.products];
      variants
        .sort((a, b) => a.position - b.position)
        .forEach(product => {
          const menuProduct =
            productsQuery.data.value?.takeawayMenuProduct[product.takeawayMenuProductId];
          if (menuProduct && !menuProduct.attributes.snoozed) {
            acc[curr.id].push(menuProduct);
          }
        });

      return acc;
    }, {} as Record<string, TakeawayMenuProduct[]>);
  });

  const categoryNamesWithProducts = computed(() => {
    if (!categories.value) return [];
    return Object.values(categories.value)
      .filter(category => {
        return productInCategories.value?.[category.id].length;
      })
      .map(category => category.attributes.name);
  });

  const upsellCategories = computed(() => {
    if (!categories.value) return [];
    return Object.values(categories.value).filter(category =>
      upsellCategoriesNames.includes(category.attributes.name)
    );
  });

  const filterKeys = (filter: string) => {
    if (!productInCategories.value) return [];
    if (!filter) return Object.keys(productInCategories.value);
    return Object.keys(productInCategories.value).filter(
      key => categories.value?.[key].attributes.name === filter
    );
  };

  const getCategoriesForProduct = (productId: string): TakeawayMenuCategory[] => {
    if (!categories.value) return [];
    return Object.values(categories.value).filter(category =>
      category.attributes.productIds.includes(productId)
    );
  };

  const getProductForId = (productId: string) => {
    if (!productId || !productsQuery.data.value) return null;
    return productsQuery.data.value.takeawayMenuProduct[productId];
  };

  return {
    categories,
    categoryNamesWithProducts,
    isLoading,
    menuError,
    productInCategories,
    snoozedProductIds,
    upsellCategories,
    filterKeys,
    getSubProductsForProduct,
    getCategoriesForProduct,
    getProductForId,
    getTakeawayProductForProductId,
  };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(usePastaNowMenu, import.meta.hot));

export default usePastaNowMenu;
