import { defineStore } from 'pinia';

import { ApiResponse } from '@pasta-evangelists/pasta-types';
import axios from 'axios';
import { onMounted, ref } from 'vue-demi';

export interface TrustpilotReview {
  stars: number;
  createdAt: string;
  title: string;
  text: string;
  reviewUrl: string;
  consumer: {
    displayName: string;
  };
  companyReply: null;
}

export interface TrustpilotBusinessEntity {
  stars: number;
  trustScore: number;
  displayName: string;
  numberOfReviews: {
    total: number;
    oneStar: number;
    twoStars: number;
    threeStars: number;
    fourStars: number;
    fiveStars: number;
  };
  websiteUrl: 'http://pastaevangelists.com';
}

interface TrustpilotServerResponse {
  businessUnit: TrustpilotBusinessEntity;
  businessEntity: TrustpilotBusinessEntity;
  reviews: TrustpilotReview[];
  starsString: string;
}

const fetchTrustpilotInfo = async (): ApiResponse<TrustpilotServerResponse> => {
  try {
    const result = await axios.get<TrustpilotServerResponse>(
      'https://trustpilot-pasta.vercel.app/api/reviews'
    );
    return { data: result.data, error: null };
  } catch (error) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

const useTrustpilotStore = defineStore('trustpilotStore', () => {
  const loading = ref(false);
  const reviews = ref<TrustpilotReview[] | null>(null);
  const businessEntity = ref<TrustpilotBusinessEntity | null>(null);
  const starsString = ref<string | null>(null);
  const error = ref<boolean>(false);

  const fetchReviews = async () => {
    loading.value = true;
    const result = await fetchTrustpilotInfo();
    if (result.error) {
      error.value = true;
      loading.value = false;
      return;
    }

    reviews.value = result.data.reviews;
    businessEntity.value = result.data.businessEntity;
    starsString.value = result.data.starsString;
    error.value = false;
    loading.value = false;
  };

  onMounted(async () => {
    await fetchReviews();
  });

  return { loading, error, reviews, businessEntity, starsString, retry: fetchReviews };
});

export default useTrustpilotStore;
