import useCreateBasket from '@/api/mutations/pensa/createBasket';
import useDeleteBasket from '@/api/mutations/pensa/deleteBasket';
import { queries } from '@/api/queries';
import useGetBaskets from '@/api/queries/pensa/useGetBaskets';
import useBasketStore from '@/stores/basketStore';
import currentCustomerStore from '@/stores/currentCustomerStore';
import { BasketType } from '@/types/basket';
import { useQueryClient } from '@tanstack/vue-query';
import { useRouter } from 'vue-router';

let isMutating = false;

const useBasketFetcher = (basketType: BasketType) => {
  const { data: result, isError, isLoading } = useGetBaskets();
  const basketStore = useBasketStore();
  const customerStore = currentCustomerStore();
  const queryClient = useQueryClient();
  const createBasketMutation = useCreateBasket();
  const deleteBasketMutation = useDeleteBasket();
  const router = useRouter();

  const basketId = computed(() => {
    const baskets = result.value;
    if (baskets?.basket) {
      const basket = Object.values(baskets.basket).find(basket => {
        return basket.attributes.type === basketType;
      });
      return basket?.id || null;
    }
    return null;
  });

  const recipeKitBasketId = computed(() => {
    const baskets = result.value;
    if (baskets?.basket) {
      const basket = Object.values(baskets.basket).find(basket => {
        return basket.attributes.type === 'RecipeKitBasket';
      });
      return basket?.id;
    }
  });

  const takeawayBasketId = computed(() => {
    const baskets = result.value;
    if (baskets?.basket) {
      const basket = Object.values(baskets.basket).find(basket => {
        return basket.attributes.type === 'TakeawayBasket';
      });
      return basket?.id;
    }
  });

  const currentBasketId = computed(() => {
    if (basketType === 'RecipeKitBasket') return recipeKitBasketId.value;
    if (basketType === 'TakeawayBasket') return takeawayBasketId.value;
    return null;
  });

  watch(
    [isError, isLoading, currentBasketId],
    async ([error, loading, basketId]) => {
      // don't auto create takeaway baskets
      if (basketType === 'TakeawayBasket') return;

      // don't auto create recipe kit baskets if we're on the gift boxes product box page
      if (router.currentRoute.value.name === 'gift-boxes-product-box') return;

      if (error || (!loading && !basketId)) {
        if (isMutating) return;
        isMutating = true;
        try {
          await createBasketMutation.mutateAsync({ type: basketType, startSubscription: true });
        } finally {
          isMutating = false;
        }
      }
    },
    { immediate: true }
  );

  watch(
    () => customerStore.email,
    async (newEmail, oldEmail) => {
      if (!newEmail && oldEmail && basketId.value) {
        await deleteBasketMutation.mutateAsync(basketId.value);
        resetBasket();
        if (basketType === 'TakeawayBasket') return;
        createBasketMutation.mutate({ type: basketType });
      }
    }
  );

  const resetBasket = () => {
    queryClient.invalidateQueries(queries.baskets.all);
    if (basketType === 'RecipeKitBasket') {
      basketStore.$reset();
    }
  };

  return { isLoading, basketId, resetBasket };
};

export default useBasketFetcher;
