<template>
  <div class="px-4 mx-auto lg:px-6">
    <div class="flex justify-between h-16">
      <div class="flex items-center">
        <div class="flex items-center mr-4 lg:hidden">
          <button
            type="button"
            class="z-10 inline-flex items-center justify-center p-4 -mx-4 focus:outline-none"
            aria-controls="mobile-menu"
            aria-expanded="false"
            @click="$emit('openMobile')"
          >
            <span class="sr-only">Open main menu</span>
            <BurgerIcon class="size-6 text-burnt-sienna" />
          </button>
        </div>
        <div class="shrink-0">
          <a href="https://pastaevangelists.com/" aria-label="Go to homepage" class="-mt-0.5">
            <ThePastaLogo class="h-auto py-4 cursor-pointer w-[100px] text-terracota-dark" />
          </a>
        </div>

        <div class="hidden ml-12 lg:space-x-8 lg:-my-px lg:flex">
          <template v-if="isLoggedIn">
            <button
              v-for="navigationLink in links"
              :key="navigationLink.label"
              class="relative text-left text-burnt-sienna"
              @mouseover="hoverIn(navigationLink.label)"
              @mouseleave="hoverOut(navigationLink.label)"
              @focus="hoverIn(navigationLink.label)"
              @blur="hoverOut(navigationLink.label)"
            >
              <router-link
                v-if="navigationLink.name"
                :to="{ name: navigationLink.name }"
                class="inline-flex items-center shrink-0"
              >
                {{ navigationLink.label }}
                <ChevronDownIcon v-if="navigationLink.children" class="ml-2 size-4" />
              </router-link>
              <a
                v-else-if="navigationLink.path"
                class="inline-flex items-center"
                :href="navigationLink.path"
                target="_blank"
              >
                {{ navigationLink.label }}
                <SolidChevronDownIcon v-if="navigationLink.children" class="mt-0.5 size-4" />
              </a>
              <div v-else class="inline-flex items-center">
                {{ navigationLink.label }}
                <SolidChevronDownIcon v-if="navigationLink.children" class="mt-0.5 size-4" />
              </div>
              <div
                v-if="navigationLink.children && isOpen(navigationLink.label)"
                class="absolute z-10 py-6 pl-6 pr-12 space-y-4 shadow-sm bg-sandstone"
              >
                <template v-for="childLink in navigationLink.children" :key="childLink.label">
                  <a
                    class="block tracking-tighter capitalize text-burnt-sienna whitespace-nowrap hover:underline"
                    :href="childLink.path"
                    target="_blank"
                  >
                    {{ childLink.label }}
                  </a>
                </template>
              </div>
            </button>
          </template>
        </div>
      </div>
      <div class="flex items-center lg:space-x-4">
        <router-link
          v-if="!hideShopNow"
          to="/products"
          class="inline-flex px-4 text-sm lg:text-base mt-0.5 lg:px-6 primary-cta--small"
        >
          Order now
        </router-link>

        <div class="relative hidden lg:block">
          <button
            v-if="isLoggedIn"
            class="relative text-left"
            @mouseover="isMenuOpen.myAccount = true"
            @mouseleave="isMenuOpen.myAccount = false"
            @focus="isMenuOpen.myAccount = true"
            @blur="isMenuOpen.myAccount = false"
          >
            <router-link to="/account" class="inline-flex items-center pt-1">
              <div class="p-1 rounded-full bg-sandstone">
                <UserIcon class="size-8 text-burnt-sienna group-hover:text-terracota-dark" />
              </div>
            </router-link>

            <div
              v-if="isMenuOpen.myAccount"
              class="absolute z-10 py-6 pl-6 pr-10 space-y-4 shadow-md bg-sandstone -left-36"
            >
              <router-link
                to="/account"
                class="block capitalize text-burnt-sienna whitespace-nowrap hover:underline text-[17px]"
              >
                Go to my account
              </router-link>
              <button
                class="block text-burnt-sienna whitespace-nowrap hover:underline text-[17px]"
                @click="$emit('logout')"
              >
                Log out
              </button>
            </div>
          </button>

          <router-link
            v-if="!isLoggedIn && !isOnLoginOrSignup"
            to="/social-login"
            class="inline-flex items-center pt-1"
          >
            <div class="p-1 rounded-full bg-sandstone">
              <UserIcon class="size-8 text-burnt-sienna group-hover:text-terracota-dark" />
            </div>
          </router-link>
          <div
            class="absolute group-hover:block -bottom-2 right-1 w-8 h-0.5 bg-burnt-sienna"
            :class="{ hidden: !activeIcon, block: activeIcon }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { experienceLinks, giftMenuLinks, storesLinks } from '@/constants/links';
  import { ChevronDownIcon } from '@heroicons/vue/solid';
  import { camelize } from 'humps';

  interface Props {
    isLoggedIn?: boolean;
    isOnLoginOrSignup?: boolean;
    hideShopNow?: boolean;
    activeIcon?: boolean;
  }

  interface Emits {
    (e: 'openMobile'): void;
    (e: 'logout'): void;
  }

  withDefaults(defineProps<Props>(), { isLoggedIn: false, isOnLoginOrSignup: false });
  defineEmits<Emits>();

  const isMenuOpen = reactive({
    gifts: false,
    events: false,
    myAccount: false,
    ourStores: false,
  });

  const hoverIn = (label: string) => {
    const camelLabel = camelize(label);
    if (Object.keys(isMenuOpen).includes(camelLabel)) {
      const key = camelLabel as keyof typeof isMenuOpen;
      isMenuOpen[key] = true;
    }
  };

  const hoverOut = (label: string) => {
    const camelLabel = camelize(label);
    if (Object.keys(isMenuOpen).includes(camelLabel)) {
      const key = camelLabel as keyof typeof isMenuOpen;
      isMenuOpen[key] = false;
    }
  };

  const isOpen = (label: string) => {
    const camelLabel = camelize(label);
    return Object.keys(isMenuOpen).includes(camelLabel)
      ? isMenuOpen[camelLabel as keyof typeof isMenuOpen]
      : false;
  };

  const links = [
    { label: 'Takeaway', name: 'pasta-now-search-address' },
    { label: 'Recipe Kits', name: 'product-box' },
    {
      label: 'Events',
      path: 'https://pastaevangelists.com/pages/events',
      children: experienceLinks,
    },
    {
      label: 'Gifts',
      path: 'https://pastaevangelists.com/pages/gifts',
      children: giftMenuLinks,
    },
    {
      label: 'Our Stores',
      children: storesLinks,
    },
  ];
</script>
