import {
  NormalizedBasket,
  BasketItems,
  BasketItem,
  BasketShipmentItem,
} from '@pasta-evangelists/pasta-types';
import { acceptHMRUpdate, defineStore } from 'pinia';
import * as BasketApi from '@/api/basket';
import useWeeklyMenuStore from './weeklyMenusStore';
import { DataLayerCheckoutProductGA4, addToCartEvent } from '@/utils/analytics';
import dayjs from 'dayjs';
import useDeliveryConfig from '@/composables/useDeliveryConfig';
import { getProductCategoriesFromTags, getSectionTitleFromTags } from '@/utils';
import useBasketHelper from '@/composables/useBasketHelper';
import { isAxiosError } from 'axios';
import useGetBasket from '@/api/queries/pensa/useGetBasket';
import useBasketFetcher from '@/composables/useBasketFetcher';
import useDeleteBasket from '@/api/mutations/pensa/deleteBasket';
import { useQueryClient } from '@tanstack/vue-query';
import { queries } from '@/api/queries';
import useCustomerStore from '@/stores/currentCustomerStore';
import useDeliveryDatesStore from '../composables/useDeliveryDates';
import useUpdateRecipeKitBasket from '@/api/mutations/pensa/updateRecipeKitBasket';
import useRemoveItemsFromBasket from '@/api/mutations/pensa/removeItemsFromBasket';
import useAddItem from '@/api/mutations/basket/useAddItem';
import useRemoveItem from '@/api/mutations/basket/useRemoveItem';
import useUpdateQuantity from '@/api/mutations/basket/useUpdateQuantity';
import { ManualAddressInputPayload } from '@/components/BaseAddressInputs.vue';
import { findMenuForDate } from '@/utils/date';

interface VoucherState {
  valid: boolean;
  message: string;
}

interface BasketIntoMenusWithAddons {
  basket: NormalizedBasket;
  defaultWeekday: string;
}

const basketIntoMenusWithAddons = ({
  basket,
  defaultWeekday,
}: BasketIntoMenusWithAddons): Record<string, BasketItems> => {
  const itemsInMenus = Object.values(basket?.basketItem ?? {}).reduce(
    basketIntoMenu(defaultWeekday),
    {} as Record<string, BasketItems>
  );

  if (!Object.keys(itemsInMenus).length) return itemsInMenus;

  const addons = Object.values(basket?.basketItem ?? {}).filter(item => item.attributes.isAddon);

  //get first menu
  const menuKey = Object.keys(itemsInMenus)[0];

  addons.forEach(addon => {
    itemsInMenus[menuKey].push(addon);
  });

  return itemsInMenus;
};

const basketIntoMenu = (defaultWeekday: string) => (
  acc: Record<string, BasketItems>,
  curr: BasketItem
) => {
  // ignore addon products
  if (curr.attributes.isAddon) return acc;

  if (curr.attributes.mealWeek) {
    if (acc[curr.attributes.mealWeek]) {
      acc[curr.attributes.mealWeek].push(curr);
      return acc;
    }

    acc[curr.attributes.mealWeek] = [curr];
  } else {
    if (acc[defaultWeekday]) {
      acc[defaultWeekday].push(curr);
      return acc;
    }

    acc[defaultWeekday] = [curr];
  }

  return acc;
};

const offersReducer = (acc: BasketItem[], curr: BasketItem) => {
  if (curr.attributes.isAddon) {
    acc.push(curr);
  }
  return acc;
};

const useBasketStore = defineStore('basketStore', () => {
  const queryClient = useQueryClient();
  const deleteBasketMutation = useDeleteBasket();
  const customerStore = useCustomerStore();
  const deliveryDatesStore = useDeliveryDatesStore();
  const updateBasketMutation = useUpdateRecipeKitBasket();
  const weeklyMenusStore = useWeeklyMenuStore();
  const removeItemsMutation = useRemoveItemsFromBasket();
  const updateQuantityMutation = useUpdateQuantity('RecipeKitBasket');

  const addItemMutation = useAddItem();
  const removeItemMutation = useRemoveItem('RecipeKitBasket');

  const voucherState = reactive<VoucherState>({ valid: true, message: '' });

  const basketFetcher = useBasketFetcher('RecipeKitBasket');
  const { data: normalizedBasket } = useGetBasket(basketFetcher.basketId);

  const basketKey = computed(() => {
    return basketFetcher.basketId.value;
  });

  const basketHelper = useBasketHelper({ basketId: basketKey, basket: normalizedBasket });

  const baseDeliveryFee = computed(() => {
    if (!basketHelper.shipment.value) return 0;
    return basketHelper.shipment.value.relationships.items.data.reduce((acc, curr) => {
      const foundShipmentItem = normalizedBasket.value?.shipmentItem?.[curr.id];
      if (!foundShipmentItem) return acc;
      if (!foundShipmentItem.attributes.root) return acc;
      return acc + parseFloat(foundShipmentItem.attributes.price);
    }, 0);
  });

  const baseDeliveryFeeInPounds = computed(() => {
    if (!baseDeliveryFee.value) return '';
    return `£${baseDeliveryFee.value.toFixed(2)}`;
  });

  const deliveryDate = computed(() => {
    if (!basketKey.value || !normalizedBasket.value?.basket) return '';
    return normalizedBasket.value.basket[basketKey.value].attributes.deliveryDate;
  });

  const extraDelivery = computed(() => {
    if (!deliveryDate) return 0;

    const deliveryIndex = dayjs(deliveryDate.value).day() || 7;

    const { config } = useDeliveryConfig();
    const deliveryConfig = config.value?.deliveryFeeConfigItem
      ? Object.values(config.value.deliveryFeeConfigItem).find(
          item => item.attributes.weekDay.index === deliveryIndex
        )
      : null;

    if (basketHelper.subtotal.value > parseFloat(deliveryConfig?.attributes.threshold || '0.00')) {
      return 0;
    }

    return parseFloat(deliveryConfig?.attributes.price || '0.00');
  });

  const extraDeliveryFees = computed(() => {
    if (!basketHelper.shipment.value) return null;
    return basketHelper.shipment.value.relationships.items.data.reduce((acc, curr) => {
      const foundShipmentItem = normalizedBasket.value?.shipmentItem?.[curr.id];
      if (!foundShipmentItem) return acc;
      if (foundShipmentItem.attributes.root) return acc;
      acc.push(foundShipmentItem);
      return acc;
    }, [] as BasketShipmentItem[]);
  });

  const extraDeliveryFee = computed(() => {
    return (
      extraDeliveryFees.value?.reduce((acc, curr) => {
        return acc + parseFloat(curr.attributes.price);
      }, 0) || 0
    );
  });

  const extraDeliveryFeeInPounds = computed(() => {
    if (!extraDeliveryFee.value) return '';
    return `£${extraDeliveryFee.value.toFixed(2)}`;
  });

  const totalDiscountInPounds = computed(() => {
    if (!basketKey.value || !normalizedBasket.value?.basket?.[basketKey.value]) return '';
    return `£${parseFloat(
      normalizedBasket.value.basket[basketKey.value].attributes.totalDiscount
    ).toFixed(2)}`;
  });

  const isCardGoingToBeCharged = computed(() => basketHelper.totalAmountToChargeOnCard.value >= 30);

  //TODO At some point we should remove this
  // if we are going to get rid of multi-week
  // we should refactor this code.
  const itemsInMenus = computed(() => {
    return Object.values(normalizedBasket.value?.basketItem ?? {}).reduce(
      basketIntoMenu(deliveryDate.value ? findMenuForDate(deliveryDate.value) : ''),
      {} as Record<string, BasketItems>
    );
  });

  const productIdsInMenus = computed(() => {
    return Object.entries(itemsInMenus.value).reduce((acc, [key, basketItems]) => {
      acc[key] = basketItems.map(item => item.attributes.productId);
      return acc;
    }, {} as Record<string, number[]>);
  });

  const amountLeftToCheckout = computed(() => {
    return (
      parseFloat(basketHelper.basketRules.value.minimumSpend.replace('£', '')) -
      basketHelper.subtotalBeforeOrderDiscounts.value
    );
  });

  const itemsinMenusWithAddons = computed<Record<string, BasketItems>>(() => {
    if (normalizedBasket.value)
      return basketIntoMenusWithAddons({
        basket: normalizedBasket.value,
        defaultWeekday: deliveryDate.value ? findMenuForDate(deliveryDate.value) : '',
      });
    return {};
  });

  const addons = computed(() => {
    if (normalizedBasket.value && normalizedBasket.value.basketItem)
      return Object.values(normalizedBasket.value.basketItem).filter(
        item => item.attributes.isAddon
      );
    return [];
  });

  const hasItems = computed(
    () =>
      normalizedBasket.value &&
      normalizedBasket.value.basketItem &&
      Object.values(normalizedBasket.value.basketItem).find(item => !item.attributes.isAddon)
  );

  const basketQuantity = computed(() => {
    if (!normalizedBasket.value || !normalizedBasket.value.basketItem) return 0;
    return Object.values(normalizedBasket.value.basketItem).reduce(
      (acc, curr) => acc + curr.attributes.quantity,
      0
    );
  });

  const offers = computed(() =>
    Object.values(normalizedBasket.value?.basketItem ?? {}).reduce(
      offersReducer,
      [] as BasketItem[]
    )
  );

  const isValid = computed(() => {
    if (!normalizedBasket.value || !normalizedBasket.value.basketItem) {
      return false;
    }
    const weeklyMenusStore = useWeeklyMenuStore();
    const validMenus =
      Object.keys(itemsInMenus.value)
        .sort((a, b) => +new Date(a) - +new Date(b))
        .map(week => {
          return itemsInMenus.value[week].reduce((acc, curr) => {
            const productVariant =
              weeklyMenusStore.productVariants?.[curr.attributes.productVariantExtId];
            if (productVariant) {
              acc +=
                parseFloat(curr.attributes.price) * curr.attributes.quantity -
                parseFloat(curr.attributes.discount);
            }
            return acc;
          }, 0);
        })
        .every((value, index) => {
          if (
            !index &&
            basketHelper.voucher.value?.attributes.singleOrder &&
            basketHelper.voucher.value.attributes.minimumSpendPerOrder
          ) {
            return value >= parseFloat(basketHelper.voucher.value.attributes.minimumSpendPerOrder);
          }
          return value >= parseFloat(basketHelper.basketRules.value.minimumSpendPerOrder);
        }) && !!Object.keys(itemsInMenus.value).length;
    if (
      basketHelper.voucher.value?.attributes.singleOrder &&
      Object.keys(itemsInMenus.value).length === 1
    ) {
      return validMenus;
    }
    if (
      basketHelper.voucher.value?.attributes.type === 'DiscountVoucher' &&
      basketHelper.voucher.value?.attributes.productVariantIds.length
    )
      return (
        validMenus &&
        basketHelper.subtotal.value >= parseFloat(basketHelper.basketRules.value.minimumSpend)
      );
    return (
      validMenus &&
      basketHelper.subtotalBeforeOrderDiscounts.value >=
        parseFloat(basketHelper.basketRules.value.minimumSpend)
    );
  });

  const isWeekValid = computed(() => {
    const weeklyMenusStore = useWeeklyMenuStore();
    return (week: string) => {
      if (!itemsInMenus.value[week]) return false;
      const weekMenusSorted = Object.keys(itemsInMenus.value).sort(
        (a, b) => +new Date(a) - +new Date(b)
      );
      const costOfMenu = itemsInMenus.value[week].reduce((acc, curr) => {
        const productVariant =
          weeklyMenusStore.productVariants?.[curr.attributes.productVariantExtId];
        if (productVariant) {
          acc +=
            parseFloat(curr.attributes.price) * curr.attributes.quantity -
            parseFloat(curr.attributes.discount);
        }
        return acc;
      }, 0);

      if (
        basketHelper.voucher.value?.attributes.singleOrder &&
        basketHelper.voucher.value.attributes.minimumSpendPerOrder &&
        weekMenusSorted[0] === week
      ) {
        return costOfMenu >= parseFloat(basketHelper.voucher.value.attributes.minimumSpendPerOrder);
      }

      return costOfMenu >= parseFloat(basketHelper.basketRules.value.minimumSpendPerOrder);
    };
  });

  const isMultiWeekMenus = computed(() =>
    itemsInMenus.value ? Object.keys(itemsInMenus.value).length > 1 : false
  );

  const hasDiscount = computed(() => {
    if (!basketKey.value || !normalizedBasket.value || !normalizedBasket.value.basket) return false;
    return parseFloat(normalizedBasket.value.basket[basketKey.value].attributes.totalDiscount) > 0;
  });

  const hasLineItemDiscountWithoutDiscountedProducts = computed(() => {
    if (!normalizedBasket.value || !normalizedBasket.value.basket) return false;
    if (
      !basketHelper.voucher.value ||
      !(basketHelper.voucher.value.attributes.type === 'DiscountVoucher') ||
      !basketHelper.voucher.value.attributes.productVariantIds.length
    )
      return false;
    return (
      !!Object.keys(itemsInMenus).length &&
      !Object.values(normalizedBasket.value.basketItem ?? {}).find(item =>
        basketHelper.voucher.value!.attributes.productVariantIds.includes(
          item.attributes.productVariantId
        )
      )
    );
  });

  const address = computed(() => {
    if (!basketKey.value || !normalizedBasket.value?.basket) return null;
    return normalizedBasket.value.basket[basketKey.value].attributes.address;
  });

  const hasValidAddress = computed(() => {
    return Boolean(address.value?.address1 && address.value?.city && address.value?.zip);
  });

  const addressString = computed(() => {
    if (!address.value) return '';
    if (address.value.address1 === 'ignore_me') return address.value.zip;

    const address1 = address.value.address1 ? address.value.address1 + ', ' : '';
    const address2 = address.value.address2 ? address.value.address2 + ', ' : '';
    const city = address.value.city ? address.value.city + ', ' : '';

    return `${address1}${address2}${city}${address.value.zip || ''}`;
  });

  const shouldSkipCheckoutDeliveryScreen = computed(() => {
    return Boolean(customerStore.firstName && customerStore.lastName && address.value?.address1);
  });

  const frequency = computed(() => {
    if (!basketKey.value || !normalizedBasket.value?.basket) return null;
    return normalizedBasket.value.basket[basketKey.value].attributes.frequency;
  });

  const isSubscription = computed(() => {
    if (!basketKey.value || !normalizedBasket.value?.basket) return null;
    return !!normalizedBasket.value.basket[basketKey.value].attributes.startSubscription;
  });

  const oneOffFeeApplicable = computed(() => {
    if (!basketKey.value || !normalizedBasket.value?.basket) return null;
    return !!normalizedBasket.value.basket[basketKey.value].attributes.oneOffFeeApplicable;
  });

  const deliveryNotes = computed(() => {
    if (!basketKey.value || !normalizedBasket.value?.basket) return '';
    return normalizedBasket.value.basket[basketKey.value].attributes.deliveryNote;
  });

  const isBasketLoaded = computed(() => {
    if (!basketKey.value || !normalizedBasket.value?.basket) return false;
    return !!normalizedBasket.value?.basket[basketKey.value].id;
  });

  const getProductBasketId = ({ variantId }: { variantId: number }) => {
    if (!normalizedBasket.value || !normalizedBasket.value.basketItem) return undefined;
    return Object.values(normalizedBasket.value.basketItem).find(
      item => item.attributes.productVariantExtId === variantId.toString()
    )?.id;
  };

  const getProductQuantity = (id: string) => {
    return normalizedBasket.value?.basketItem?.[id]?.attributes?.quantity ?? 0;
  };

  const incrementQuantity = (id: string) => {
    if (!basketKey.value || !normalizedBasket.value?.basketItem?.[id]) return;

    updateQuantityMutation.mutate({
      basketId: basketKey.value,
      itemId: id,
      quantity: normalizedBasket.value.basketItem[id].attributes.quantity + 1,
    });
  };

  interface UpdateQuantityWithVariantParams {
    productVariantId: number;
  }

  const incrementVariant = ({ productVariantId }: UpdateQuantityWithVariantParams) => {
    const basketIdFound =
      getProductBasketId({
        variantId: productVariantId,
      }) ?? '';
    if (basketIdFound) {
      incrementQuantity(basketIdFound);
    }
  };

  const decrementQuantity = (id: string) => {
    if (!basketKey.value || !normalizedBasket.value?.basketItem?.[id]) return;

    const oldQuantity = normalizedBasket.value.basketItem[id].attributes.quantity;

    if (oldQuantity === 1) {
      removeItemMutation.mutate({
        itemId: id,
        basketId: basketKey.value,
      });
    } else {
      updateQuantityMutation.mutate({
        basketId: basketKey.value,
        itemId: id,
        quantity: normalizedBasket.value.basketItem[id].attributes.quantity - 1,
      });
    }
  };

  const decrementVariant = ({ productVariantId }: UpdateQuantityWithVariantParams) => {
    const basketIdFound =
      getProductBasketId({
        variantId: productVariantId,
      }) ?? '';
    if (basketIdFound) {
      decrementQuantity(basketIdFound);
    }
  };

  const removeItem = (id: string) => {
    if (
      !basketKey.value ||
      !normalizedBasket.value?.basketItem?.[id] ||
      !normalizedBasket.value.basket
    ) {
      return;
    }

    removeItemMutation.mutate({
      itemId: id,
      basketId: basketKey.value,
    });
  };

  type AnalyticsData = {
    source?: string;
    weekIndex?: number;
    menuType?: string;
  };

  type AddItemParams = Omit<BasketApi.ItemParams, 'basketId'> & AnalyticsData;

  const addItem = async (item: AddItemParams) => {
    if (!basketKey.value) return;
    try {
      await addItemMutation.mutateAsync({ ...item, basketId: basketKey.value });
      const weeklyMenusStore = useWeeklyMenuStore();

      const product = weeklyMenusStore.productVariants?.[item.productVariantId];
      if (product) {
        const tags = weeklyMenusStore.productVariants?.[item.productVariantId]?.tags ?? [];
        const category = getProductCategoriesFromTags(
          tags
        ) as DataLayerCheckoutProductGA4['item_category'];
        const category2 = getSectionTitleFromTags(tags);

        const cartData = {
          value: item.quantity * parseFloat(product.price),
          add_to_cart_source: item.source,
          add_to_cart_menu_week: item.weekIndex,
          menu_type: item.menuType,
        };
        addToCartEvent(cartData, [
          {
            item_id: item.productVariantId.toString(),
            item_name: product.title,
            price: parseFloat(product.price),
            quantity: item.quantity,
            item_variant: product.isDouble ? 'Grande' : 'Single',
            item_brand: 'Shopify',
            item_category: category,
            item_category2: category2,
          },
        ]);
      }
    } catch {
      return;
    }
  };

  const validateVoucher = async (messageOverride = '') => {
    try {
      await BasketApi.validateVoucher({ basketId: basketKey.value || '' });
      voucherState.valid = true;
      voucherState.message = '';
    } catch (e: unknown) {
      voucherState.valid = false;
      const message = isAxiosError(e) ? e.message : 'Voucher invalid';
      voucherState.message = messageOverride || message;
    }
  };

  const resetVoucherError = () => {
    voucherState.valid = true;
    voucherState.message = '';
  };

  const isVariantInBasket = (variants: number[]) => {
    return (
      normalizedBasket.value?.basketItem &&
      Object.values(normalizedBasket.value.basketItem).find(item =>
        variants.includes(item.attributes.productVariantId)
      )
    );
  };

  const deleteBasket = async () => {
    if (!basketKey.value) return;
    return deleteBasketMutation.mutateAsync(basketKey.value, {
      onSuccess: () => {
        queryClient.removeQueries(queries.baskets.byId(basketKey));
        queryClient.refetchQueries(queries.baskets.all);
      },
    });
  };

  const deleteInvalidProducts = async (menuDate: string) => {
    const menuWeekProducts = weeklyMenusStore.menus[menuDate]?.data;
    const invalidProducts = Object.values(normalizedBasket.value?.basketItem || {})?.filter(
      item => {
        const isWeekCorrect = !!menuWeekProducts?.find(menu =>
          menu.variants.find(variant => variant.id === item.attributes.productVariantExtId)
        );
        return !isWeekCorrect && !item.attributes.isAddon;
      }
    );
    if (invalidProducts.length > 0 && basketKey.value)
      await removeItemsMutation.mutateAsync({
        basketId: basketKey.value,
        ids: invalidProducts.map(item => item.id),
      });
    return invalidProducts;
  };

  const updateAddressWhenBasketAvailable = async (address: ManualAddressInputPayload) => {
    const timeout = 10000; // 10 seconds timeout
    const pollInterval = 100; // Polling interval in milliseconds
    const maxAttempts = timeout / pollInterval;
    let attempts = 0;

    const pollBasketLoaded = async () => {
      if (isBasketLoaded.value) {
        await updateBasketMutation.mutateAsync({
          id: basketKey.value!,
          address,
        });
      } else if (attempts < maxAttempts) {
        attempts++;
        await new Promise(resolve => setTimeout(resolve, pollInterval));
        await pollBasketLoaded();
      } else {
        throw new Error('Basket loading timeout');
      }
    };

    await pollBasketLoaded();
  };

  const $reset = () => {
    voucherState.valid = true;
    voucherState.message = '';
  };

  // when the basket doesn't have a delivery date
  // or has an invalid delivery date we will set
  // the first available delivery date as the delivery date
  watch(
    [basketKey, deliveryDatesStore.firstAvailableDate, deliveryDatesStore.disabledDates],
    ([newBasketKey, firstAvailableDate, disabledDates]) => {
      if (!newBasketKey || !firstAvailableDate) return;
      const isDisabledDate = deliveryDate.value
        ? disabledDates.includes(deliveryDate.value)
        : false;

      if (
        dayjs(firstAvailableDate.value).isAfter(dayjs(deliveryDate.value)) ||
        !deliveryDate.value ||
        isDisabledDate
      ) {
        updateBasketMutation.mutate({
          id: newBasketKey,
          deliveryDate: firstAvailableDate.value,
        });
      }
    }
  );

  watch(basketHelper.voucher, () => {
    if (!basketHelper.voucher.value) {
      resetVoucherError();
    }
  });

  return {
    ...basketHelper,
    addons,
    baseDeliveryFeeInPounds,
    basketKey,
    basketQuantity,
    extraDelivery,
    extraDeliveryFees,
    extraDeliveryFeeInPounds,
    hasDiscount,
    hasItems,
    hasLineItemDiscountWithoutDiscountedProducts,
    isCardGoingToBeCharged,
    isMultiWeekMenus,
    itemsInMenus,
    itemsinMenusWithAddons,
    isValid,
    isWeekValid,
    normalizedBasket,
    offers,
    productIdsInMenus,
    totalDiscountInPounds,
    voucherState,
    address,
    addressString,
    deliveryDate,
    hasValidAddress,
    shouldSkipCheckoutDeliveryScreen,
    frequency,
    deliveryNotes,
    isSubscription,
    oneOffFeeApplicable,
    isBasketLoaded,
    amountLeftToCheckout,
    addItem,
    decrementQuantity,
    deleteBasket,
    getProductBasketId,
    getProductQuantity,
    incrementQuantity,
    incrementVariant,
    decrementVariant,
    isVariantInBasket,
    removeItem,
    validateVoucher,
    deleteInvalidProducts,
    updateAddressWhenBasketAvailable,
    resetVoucherError,
    $reset,
  };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useBasketStore, import.meta.hot));

export default useBasketStore;
