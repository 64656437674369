import {
  LineItem,
  NormalizedOrderWithAddressIncluded,
  Order,
  NormalizedOrder,
  notEmpty,
} from '@pasta-evangelists/pasta-types';
import { acceptHMRUpdate, defineStore } from 'pinia';
import useSubscriptionStore from './subscriptionStore';
import useScheduleStore from './scheduledStore';
import merge from 'lodash.merge';
import { checkPaymentFail } from '@/utils';
import { useQueryClient } from '@tanstack/vue-query';
import { queries } from '@/api/queries';
import useGetUpcomingOrders from '@/api/queries/pensa/useGetUpcomingOrders';
import useEditUpcomingOrder from '@/api/mutations/pensa/editUpcomingOrder';
import { useCloned } from '@vueuse/core';
import useGetOrderHistory from '@/api/queries/pensa/useGetOrderHistory';

export const transformOrder = (
  order: Order,
  orders: NormalizedOrderWithAddressIncluded
): NormalizedOrder => {
  const subscriptionStore = useSubscriptionStore();
  const scheduledWeekStore = useScheduleStore();
  const ordersStore = useOrdersStore();

  const billingObject =
    order.relationships.orderBillingAddress.data && orders.orderAddress
      ? orders.orderAddress[order.relationships.orderBillingAddress.data.id]
      : null;
  const billingAddress = billingObject
    ? { ...billingObject.attributes, id: billingObject.id }
    : null;
  const deliveryObject =
    order.relationships.orderShippingAddress.data && orders.orderAddress
      ? orders.orderAddress[order.relationships.orderShippingAddress.data.id]
      : null;
  const deliveryAddress = deliveryObject
    ? { ...deliveryObject.attributes, id: deliveryObject.id }
    : null;

  let customerVoucher = null;

  const customerVoucherId = order.relationships.customerVoucher.data?.id;

  if (
    customerVoucherId &&
    ordersStore.upcomingOrders.normalizedOrders?.customerVoucher &&
    ordersStore.upcomingOrders.normalizedOrders?.customerVoucher[customerVoucherId] &&
    ordersStore.upcomingOrders.normalizedOrders?.voucher
  ) {
    const voucherId =
      ordersStore.upcomingOrders.normalizedOrders?.customerVoucher?.[customerVoucherId]
        ?.relationships.voucher.data.id;

    customerVoucher = {
      id: customerVoucherId,
      attributes:
        ordersStore.upcomingOrders.normalizedOrders.customerVoucher[customerVoucherId].attributes,
      voucher: {
        id: voucherId,
        attributes: ordersStore.upcomingOrders.normalizedOrders.voucher[voucherId].attributes,
      },
    };
  }

  // An one-off order doesn't have an associated subscription
  const subscription =
    subscriptionStore.normalizedSubscriptions && order.relationships.subscription.data
      ? subscriptionStore.getTransformedSubscription(order.relationships.subscription.data.id)
      : null;

  const restaurantObject = order.relationships.restaurant?.data
    ? orders.restaurant?.[order.relationships.restaurant.data.id]
    : null;
  const restaurant = restaurantObject
    ? { id: restaurantObject.id, ...restaurantObject.attributes }
    : null;

  const products = order.relationships.lineItems.data
    .filter(
      lineItem =>
        Boolean(orders!.lineItem?.[lineItem.id]) &&
        !orders.lineItem[lineItem.id].attributes.parentLineItem
    )
    .map(lineItem => {
      const subItems = orders!.lineItem[lineItem.id].relationships.subItems.data.length
        ? orders!.lineItem[lineItem.id].relationships.subItems.data.map(
            subItem => orders!.lineItem[subItem.id]
          )
        : undefined;
      return {
        ...orders!.lineItem[lineItem.id].attributes,
        id: orders!.lineItem[lineItem.id].id,
        imageUrl: orders!.lineItem[lineItem.id].attributes.imageUrl || '',
        productRating: orders!.lineItem[lineItem.id].relationships.productRating?.data || null,
        productVariantId: orders!.lineItem[lineItem.id].attributes.productVariantId,
        subItems,
      };
    });

  const scheduledWeek = scheduledWeekStore.ordersInScheduledOrders?.[order.id] ?? null;

  const feedbackOrderId = order.relationships.orderFeedback.data?.id;
  let feedback = null;

  if (feedbackOrderId && orders.orderFeedback?.[feedbackOrderId]) {
    const { notes, npsRating, reason } = orders.orderFeedback[feedbackOrderId].attributes;

    feedback = {
      id: feedbackOrderId,
      notes,
      npsRating,
      reason,
    };
  }

  const shipmentObject =
    orders.shipment && order.relationships.shipment.data
      ? orders.shipment[order.relationships.shipment.data.id]
      : null;

  const shipment = shipmentObject
    ? {
        id: shipmentObject.id,
        ...shipmentObject.attributes,
      }
    : null;

  const shipmentItems =
    shipmentObject?.relationships.items.data
      ?.map(shipmentItemId => {
        if (!orders.shipmentItem) return null;
        const shipmentItem = orders.shipmentItem[shipmentItemId.id];
        return {
          id: shipmentItem.id,
          description: shipmentItem.attributes.description,
          price: shipmentItem.attributes.price,
          threshold: shipmentItem.attributes.threshold,
          root: shipmentItem.attributes.root,
        };
      })
      .filter(notEmpty) || [];

  const { id, attributes } = order;

  const { totalDiscounts, ...rest } = attributes;
  const newAttributes = { ...rest, discount: totalDiscounts };

  const localOrder = {
    id,
    billingAddress,
    deliveryAddress,
    products,
    attributes: newAttributes,
    subscription,
    scheduledWeek,
    customerVoucher,
    feedback,
    shipment,
    shipmentItems,
    restaurant,
  };

  return localOrder;
};

const useOrdersStore = defineStore('orderStore', () => {
  const queryClient = useQueryClient();
  const upcomingOrdersData = useGetUpcomingOrders();
  const {
    data: orderHistoryData,
    isPending: isFetchingOrderHistory,
    hasNextPage: hasMorePastOrders,
    fetchNextPage: fetchMorePastOrders,
    isFetchingNextPage: isFetchingMorePastOrders,
  } = useGetOrderHistory();

  const editUpcomingOrderMutation = useEditUpcomingOrder();

  const upcomingOrders = computed(() => {
    return {
      normalizedOrders: upcomingOrdersData.data.value || null,
      loading: upcomingOrdersData.isLoading.value,
      error: upcomingOrdersData.error.value,
    };
  });

  const orderHistory = computed(() => {
    const normalizedOrders = orderHistoryData.value
      ? orderHistoryData.value.pages.reduce((acc, cur) => {
          if (!cur?.normalizedOrders) return acc;
          merge(acc, cur.normalizedOrders);
          return acc;
        }, {} as NormalizedOrderWithAddressIncluded)
      : null;
    return {
      normalizedOrders,
      loading: isFetchingOrderHistory.value,
    };
  });

  const upcoming = computed(() => {
    if (!upcomingOrders.value.normalizedOrders?.order) return null;
    const orderDateObject: Record<string, NormalizedOrder[]> = {};
    const orders = upcomingOrders.value.normalizedOrders;

    Object.values(upcomingOrders.value.normalizedOrders.order).forEach(order => {
      const transformedOrder = transformOrder(order, orders);

      if (orderDateObject[order.attributes.deliveryDate]) {
        orderDateObject[order.attributes.deliveryDate].push(transformedOrder);
      } else {
        orderDateObject[order.attributes.deliveryDate] = [transformedOrder];
      }
    });

    return orderDateObject;
  });

  const upcomingNormalized = computed(() => {
    if (!upcomingOrders.value.normalizedOrders?.order) return null;
    const orderDateObject: Record<string, NormalizedOrder> = {};
    const orders = upcomingOrders.value.normalizedOrders;

    Object.values(upcomingOrders.value.normalizedOrders.order).forEach(order => {
      const transformedOrder = transformOrder(order, orders);
      orderDateObject[order.id] = transformedOrder;
    });

    return orderDateObject;
  });

  const orderHistoryNormalized = computed(() => {
    if (!orderHistory.value.normalizedOrders?.order) return null;
    const orderDateObject: Record<string, NormalizedOrder> = {};

    Object.values(orderHistory.value.normalizedOrders.order).forEach(order => {
      if (order.attributes.state === 'dropped') return;

      if (orderHistory.value.normalizedOrders) {
        const transformedOrder = transformOrder(order, orderHistory.value.normalizedOrders);
        orderDateObject[order.id] = transformedOrder;
      }
    });

    return orderDateObject;
  });

  const upcomingNormalizedOneOffs = computed(() => {
    if (!upcomingOrders.value.normalizedOrders?.order) return null;
    const orderDateObject: Record<string, NormalizedOrder> = {};
    const orders = upcomingOrders.value.normalizedOrders;

    Object.values(upcomingOrders.value.normalizedOrders.order).forEach(order => {
      if (!order.relationships.subscription.data) {
        const transformedOrder = transformOrder(order, orders);
        orderDateObject[order.id] = transformedOrder;
      }
    });

    return orderDateObject;
  });

  const upcomingNormalizedAmendedOrders = computed(() => {
    if (!upcomingOrders.value.normalizedOrders?.order) return null;
    const orderDateObject: Record<string, NormalizedOrder> = {};
    const orders = upcomingOrders.value.normalizedOrders;

    Object.values(upcomingOrders.value.normalizedOrders.order).forEach(order => {
      if (order.attributes.source === 'shopify' && order.attributes.subscriptionId) {
        const transformedOrder = transformOrder(order, orders);
        orderDateObject[order.id] = transformedOrder;
      }
    });

    return orderDateObject;
  });

  const ordersForSubscriptions = computed(() => {
    if (!upcomingNormalized.value) return null;
    const subscriptionObject: Record<string, Record<string, NormalizedOrder>> = {};

    Object.values(upcomingNormalized.value).forEach(order => {
      if (order.subscription && subscriptionObject[order.subscription.id]) {
        subscriptionObject[order.subscription?.id][order.id] = order;
      } else if (order.subscription) {
        subscriptionObject[order.subscription?.id] = { [order.id]: order };
      }
    });
    return subscriptionObject;
  });

  const upcomingOrdersForCurrentSubscription = computed<NormalizedOrder[] | null>(() => {
    const subscriptionStore = useSubscriptionStore();
    if (!upcomingNormalized.value) return null;

    return Object.values(upcomingNormalized.value).filter(
      order => order.subscription?.id === subscriptionStore.selectedId
    );
  });

  const ordersWithFailedPayment = computed<NormalizedOrder[]>(() => {
    if (!upcomingNormalized.value) return [];

    return Object.values(upcomingNormalized.value).filter(
      order => checkPaymentFail(order) && order.subscription?.state !== 'cancelled'
    );
  });

  const getUpcomingOrders = upcomingOrdersData.refetch;

  const getUpcomingOrder = (id: string) => {
    if (
      !upcomingOrders.value.normalizedOrders ||
      !upcomingOrders.value.normalizedOrders.order?.[id]
    )
      return null;
    return transformOrder(
      upcomingOrders.value.normalizedOrders.order[id],
      upcomingOrders.value.normalizedOrders
    );
  };

  const getUpcomingOrdersForSubscription = (subId: string): NormalizedOrder[] => {
    if (!upcomingOrders.value.normalizedOrders?.order) return [];
    return Object.values(upcomingOrders.value.normalizedOrders.order)
      .filter(upcomingOrder => upcomingOrder.relationships.subscription.data?.id === subId)
      .map(order => transformOrder(order, upcomingOrders.value.normalizedOrders!));
  };

  const addItemToUpcomingOrder = async (orderId: string, lineItem: LineItem) => {
    const item = {
      productVariantId: lineItem.attributes.productVariantExtId.toString(),
      quantity: lineItem.attributes.quantity,
    };
    const order = getUpcomingOrder(orderId);

    if (!order || !order.scheduledWeek || !upcomingOrders.value.normalizedOrders) return;

    const items = order.products.map(product => ({
      productVariantId: product.productVariantExtId.toString(),
      quantity: product.quantity,
    }));

    queryClient.setQueriesData<NormalizedOrderWithAddressIncluded | null>(
      queries.orders.upcoming,
      orders => {
        const { cloned: tempOrders } = useCloned(orders);
        if (tempOrders.value) {
          tempOrders.value.lineItem[lineItem.id] = lineItem;
          if (
            !tempOrders.value.order[orderId].relationships.lineItems.data.find(
              li => li.id === lineItem.id
            )
          )
            tempOrders.value.order[orderId].relationships.lineItems.data.push({
              id: lineItem.id,
              type: 'line_item',
            });
        }
        return tempOrders.value;
      }
    );

    try {
      await editUpcomingOrderMutation.mutateAsync({
        orderId,
        params: {
          scheduleWeekId: order.scheduledWeek.id,
          items: [...items, item],
        },
      });
    } catch (e) {
      queryClient.setQueriesData<NormalizedOrderWithAddressIncluded | null>(
        queries.orders.upcoming,
        orders => {
          const { cloned: tempOrders } = useCloned(orders);
          if (tempOrders.value) {
            delete tempOrders.value.lineItem[lineItem.id];
            tempOrders.value.order[orderId].relationships.lineItems.data = tempOrders.value.order[
              orderId
            ].relationships.lineItems.data.filter(dataItem => dataItem.id !== lineItem.id);
          }
          return tempOrders.value;
        }
      );
      queryClient.invalidateQueries(queries.orders.upcoming);
    }
  };

  return {
    orderHistory,
    orderHistoryNormalized,
    hasMorePastOrders,
    isFetchingMorePastOrders,
    upcomingOrders,
    upcoming,
    upcomingNormalized,
    upcomingNormalizedAmendedOrders,
    upcomingNormalizedOneOffs,
    upcomingOrdersForCurrentSubscription,
    ordersForSubscriptions,
    ordersWithFailedPayment,
    fetchMorePastOrders,
    getUpcomingOrders,
    getUpcomingOrder,
    getUpcomingOrdersForSubscription,
    addItemToUpcomingOrder,
  };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useOrdersStore, import.meta.hot));

export default useOrdersStore;
