<template>
  <div class="flex flex-col h-full overflow-auto md:flex-row md:overflow-hidden bg-sandstone">
    <div class="w-full md:w-1/2">
      <img
        alt="How it works banner image"
        class="z-0 object-cover size-full max-h-[25svh] md:max-h-full aspect-[4/3] md:aspect-[16/9]"
        height="310"
        loading="lazy"
        src="https://cdn.shopify.com/s/files/1/1725/5449/files/ab6edda266dc1603de3c3ffa98ff13a5_1_1.webp?v=1714649033"
        width="1036"
      />
    </div>
    <div class="w-full p-6 lg:w-1/2 lg:p-12 lg:overflow-auto min-h-[80svh]">
      <h2 class="mb-2 text-4xl font-light text-center text-terracota-dark font-brand">
        How it works
      </h2>
      <p class="pb-1 text-center text-burnt-sienna">
        We send you everything you need to prepare a restaurant quality pasta dish at home,
        including freshly made pasta, an authentic sauce & delicious garnish.
      </p>
      <hr class="my-4 border-dark-linen" />
      <ul class="text-off-black">
        <li v-for="element in list" :key="element.alt" class="flex items-center mb-4 leading-tight">
          <img
            :src="element.imageUrl"
            :alt="element.alt"
            class="p-1 mr-2 rounded-full size-8 bg-dark-linen"
          />
          {{ element.text }}
        </li>
      </ul>
      <hr class="my-4 border-dark-linen" />
      <h2 class="mb-2 text-2xl font-light text-center text-terracota-dark font-brand">
        Frequently asked questions
      </h2>
      <FAQAccordion
        v-for="faq in faqs"
        :key="faq.question"
        :question="faq.question"
        :answer="faq.answer"
        classes="pt-4 hover:border-terracota-dark"
        icon-classes="text-off-black size-4 group-hover:text-terracota-dark"
        text-classes="text-off-black group-hover:text-terracota-dark"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  defineEmits<{ (e: 'close'): void }>();

  const list = [
    {
      imageUrl: 'https://cdn.shopify.com/s/files/1/1725/5449/files/Tick2.svg?v=1714647210',
      text: 'No commitment. Skip, pause or cancel anytime',
      alt: 'check icon',
    },
    {
      imageUrl: 'https://cdn.shopify.com/s/files/1/1725/5449/files/Cutlery.svg?v=1714645022',
      text: 'New menu of 20+ dishes each week',
      alt: 'cutlery icon',
    },
    {
      imageUrl: 'https://cdn.shopify.com/s/files/1/1725/5449/files/Thumbup.svg?v=1714645010',
      text: "Handpick dishes or we'll suggest dishes based on your preferences",
      alt: 'thumbup icon',
    },
    {
      imageUrl: 'https://cdn.shopify.com/s/files/1/1725/5449/files/Freeze.svg?v=1714647202',
      text: 'Freeze dishes for up to 1 month',
      alt: 'freeze icon',
    },
  ];

  const faqs = [
    {
      question: "What's included and how do I store it?",
      answer:
        "Each recipe includes everything you need to prepare a 5* pasta dish, including freshly made pasta, an authentic sauce & delicious garnish. You'll also receive step-by-step instructions for each recipe (don't worry, it couldn't be easier & takes just minutes). Meals should be consumed by the use-by-date on the packaging or frozen by this time to enjoy within 1 month. Each recipe includes helpful instructions for preparing your meal from frozen.",
    },
    {
      question: 'Where do you deliver?',
      answer:
        'We deliver nationwide, 7 days a week, including to Northern Ireland, the Channel Islands and Hebrides. Unfortunately, if you live in the Scottish Highlands or non-mainland UK we aren’t able to deliver to you on a Monday or Sunday.',
    },
    {
      question: 'Do you offer next day delivery?',
      answer:
        'The short answer is: yes! Just place your order before 5pm, Monday - Thursday, to get next day delivery. On Sundays, place your order before 8am.',
    },
    {
      question: 'What if I’m not home for delivery?',
      answer:
        "No problem. Just let us know a safe place for your delivery driver to leave your order, then collect it when you get home. We save your most recent delivery instructions on your account, and this will be used for each new order. If you ever want to update your instructions, simply log into your account select the 'Plan Settings' tab, click 'Edit' under 'Delivery instructions', and update your delivery instructions from the drop-down list or select 'Other' to enter your own safe place.",
    },
  ];
</script>
